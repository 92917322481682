import { HStack, VStack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import React, { useEffect, useState } from "react";
import { usePageContext } from "../../../../contexts/page.context";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "../../../../components/menu";
import { Text } from "../../../../components/text";
import { Coins, useAddFundsContext } from "../../addfunds.context";
import NextButton from "./../../../../components/NextButton";
import { numberWithCommas } from "../../../../../../utilities/functions";
import useAddFundsStore from "../../store";
import classes from "../../../sendfunds/ui/crypto/CryptoInput.module.css"
import DropGainDownIcon from "../../../../../../icons/DropGainDownIcon";

const CryptoInput = () => {
  const { lightMode } = usePageContext();
  const [amount, setAmount] = useState("");
  const [coin, setCoin] = useState("");
  const nextPage = useAddFundsStore((s) => s.nextPage);
  const completePage = useAddFundsStore((s) => s.completePage);
  const inCompletePage = useAddFundsStore((s) => s.inCompletePage);
  const saveCryptoDetails = useAddFundsStore((s) => s.saveCryptoDetails);


  const [showMenu, setShowMenu] = useState(false); // Track visibility of main menu
  const [openSubmenu, setOpenSubmenu] = useState(null); // Track the open submenu item

  const handleMenuToggle = () => {
    setShowMenu(!showMenu); // Toggle main menu visibility
    setOpenSubmenu(null); // Reset any open submenu when main menu is toggled
  };

  const handleSubmenuToggle = (submenu) => {
    // Toggle submenu visibility
    setOpenSubmenu(openSubmenu === submenu ? null : submenu);
  };

  const handleSubmit = () => {
    if (parseFloat(amount) >= 10000) {
      saveCryptoDetails({
        coin,
        amount,
      });
      nextPage();
    }
  };

  useEffect(() => {
    if (!!amount && !!coin) completePage();
    else inCompletePage();
    return () => {};
  }, [amount, coin]);

  return (
    <HStack
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"flex-end"}
      gap={"53px"}
    >
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Coin
        </Text>
        <Menu
          width={"100%"}
          px={"20px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          py={"12px"}
          onChange={(str) => setCoin(str)}
          value={coin}
        >
          <MenuButton placeholder={"Select coin"} />
          <MenuList>
            {Coins.map((x, i) => (
              <MenuItem disabled={x !== "USDT"} key={i}>
                {x}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
         {/* <div className={classes.contextMenu}>
      <div 
        onClick={handleMenuToggle} 
        className={`${classes.selectItemButton} ${showMenu ? classes.open : ''} d-flex justify-content-between align-items-center`}
      >
       <div>{coin ? coin : "Select coin"}</div> <DropGainDownIcon color="#111" dropDown={showMenu} height={11} width={14}/>
      </div>
      {showMenu && (
        <ul className={classes.menu}>
          {Coins.map((x, i) => (
          <li key={i}>
            <button onClick={() => handleSubmenuToggle(i)} className={classes.menuButton} disabled={x !== "USDT"}>{x}</button>
            {openSubmenu === i && (
              <ul className={classes.submenu}>
                <h6>Select Network</h6>
                {x.subMenu.map((item, index) => (<li key={index} onClick={() => {
                 setCoin( x + " - " + item)
              setShowMenu(false);
              setOpenSubmenu(null);
              }}>{item}</li>))}
              </ul>
            )}
          </li>))}
        </ul>
      )}
    </div> */}
      </VStack>
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Amount
        </Text>
        <HStack
          maxWidth={"460px"}
          borderRadius={"50px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          px={"18px"}
          py={"12px"}
        >
          <Input
            px={0}
            value={amount ? "$" + numberWithCommas(amount) : ""}
            height={"100%"}
            flexGrow={1}
            color={lightMode ? "black" : "white"}
            onChange={(e) => {
              const str = e.target.value.replace("$", "").replaceAll(",", "");
              if (str.length === 0) setAmount("");
              if (/\d/.test(str))
                setAmount(e.target.value.replace("$", "").replaceAll(",", ""));
            }}
            border={"none"}
            outlineColor={"transparent"}
            focusBorderColor={"transparent"}
            _placeholder={{
              color: lightMode ? "#7f7f7f" : "#d0d0d0",
            }}
            fontSize={"14px"}
            bg={"transparent"}
            placeholder="Min. $10,000"
          />
          <Text fontSize={"14px"} color={lightMode ? "black" : "white"}>
            USD
          </Text>
        </HStack>
      </VStack>
      <NextButton
        active={!!amount && !!coin && parseFloat(amount) >= 10000}
        onSubmit={handleSubmit}
      />
    </HStack>
  );
};

export default CryptoInput;
