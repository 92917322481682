import { useEffect, useState } from 'react'
import classes from './NotificationsSlider.module.css'
import BellIcon from '../../icons/BellIcon'
import { env } from '../../constants/env'
import { getCookie } from '../../utilities/functions'
import { io } from 'socket.io-client'
import { useNavigate } from "react-router-dom";

const NotificationsSlider = () => {
    const navigate = useNavigate();
    const [slideIn, setSlideIn] = useState(false)
    const [slideOut, setSlideOut] = useState(false)
    const [hover, setHover] = useState(false)
    const [index, setIndex] = useState([])

    const [notifications, setNotifications] = useState([])

    const userId = getCookie('userId')

    // useEffect(() => {
      
    //     setInterval(function() {
    //       if(!hover){
    //       setSlideOut(true)
    //       }
    //     }, 5000);
    // },[slideIn])

    console.log("SLIDE STATUS",slideOut)

    useEffect(() => {
        const nsocket = io(env.notificationsUrl, {
          query: 'user=' + userId
        })
        nsocket.on('new-notification', notificationListener)
        
        getAllNotifications()
        return () => {
          nsocket.off('new-notification', notificationListener)
        }
    }, [userId])


    const notificationListener = async() =>{
        await getAllNotifications()
    }


    const getAllNotifications = async () => {
        const sendRequest = async () => {
          const response = await fetch(`${env.notificationsUrl}/notifications/${userId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
    
          if (!response.ok) {
            throw new Error("sending plays failed.");
          }
    
          const result = await response?.json();

            var newArray = result?.docs?.filter(function (el) {
                return el.status === "unread" && el.sort !== "later"
            });

            setNotifications(newArray);

            setSlideIn(true)
            

            // console.log('All Notify', newArray)

            // if(newArray.length > 0){
            //     setSlideIn(true)
            // }else{
            //     setSlideIn(false)
            // }
    
          return result;
        };
    
        try {
          await sendRequest();
        } catch (error) {
          console.log(error);
        }
      };

      const updateStatus = async (id) => {
        const sendRequest = async () => {
          setIndex([...index,id])
          const response = await fetch(`${env.notificationsUrl}/notifications/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
          });
    
          if (!response.ok) {
            throw new Error("sending plays failed.");
          }
    
          const result = await response.json();
          setSlideIn(false)
          console.log("Is it reaching here?", result)
          var newArray = notifications.filter(function (el) {
                return el._id !== id
            });

            setNotifications(newArray)
    
          // getAllNotifications();
    
          return result;
        };
    
        try {
          await sendRequest();
        } catch (error) {
          console.log(error);
        }
      };
    return(
        <div className={`${classes.wrapper} w-100`}>
            <div className={classes.cardWrapper}>
            {notifications.map((item, i) => (<div className={`d-flex justify-content-between align-items-center ${classes.notificationCard} ${slideIn ? classes.slideIn : index.indexOf(item._id) === -1 && classes.slideUp} ${index.indexOf(item._id) !== -1 && classes.slideOut}`} key={i} >
                <div className='d-flex justify-content-between align-items-center feature-icon-close-row'>
                    <div className={`d-flex justify-content-center align-items-center ${classes.iconWrapper}`}>
                        <BellIcon />
                    </div>
                    <div className={classes.desc}>
                        <h6>Notifications</h6>
                        <p>
                        {item?.message?.length > 30 ? `${item?.message?.substring(0,30)}...` : item?.message} 
                        </p>
                    </div>
                </div>
                <div className={classes.leftSide}>
                    <div className={`${classes.topInner} `} role='button' onClick={() => {navigate("/dashboard/notifications"); updateStatus(item._id)}}>
                    Show
                    </div>
                    <div className={`${classes.bottomInner}`} role='button' onClick={() => {updateStatus(item._id)}}>
                    Later
                    </div>
                </div>
            </div>))}
            </div>
        </div>

    )
}

export default NotificationsSlider