import { Icon } from "@chakra-ui/icon";
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect, useState } from "react";
import { usePageContext } from "../../../contexts/page.context";
import DownloadIcon from "../../../components/Download.icon";
import InfoIcon from "../../../components/Info.icon";
import TransferIcon from "../../../components/Transfer.icon";
import UploadIcon from "../../../components/Upload.icon";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from "../../../../../constants/colors";
import useWalletStore from "../../../store";
import SECTIONS from "../data/sectionTitle";
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import {
  addCommas,
  getCookie,
  numberWithCommas,
} from "../../../../../utilities/functions";
import useGetBalance from "../../accounts/hooks/useGetBalance";

const WalletActions = () => {
  const { currentTheme } = usePageContext();
  const [hover, setHover] = useState(false);
  const companyId = getCookie("companyId");
  const balance = useWalletStore((s) => s.balance);
  const inputBalance = useWalletStore((s) => s.inputBalance);
  const { data } = useTradingAccounts(companyId);
  const { mutateAsync: getBalance } = useGetBalance();
  const currentSection = useWalletStore((s) => s.section);
  const changeSection = useWalletStore((s) => s.changeSection);
  const cashAccount = useWalletStore((s) => s.cashAccount);
  const setCashAccount = useWalletStore((s) => s.setCashAccount);
  const inputSource = useWalletStore((s) => s.inputSource);
  const setAddFunds = useWalletStore((s) => s.setAddFunds);

  useEffect(() => {
    if (!!data) {
      const acc = data?.find((x) => x.cashAccount);
      setCashAccount(acc?._id);
      getBalance(acc?.credentials?.apiId).then((res) => {
        inputBalance(res);
      });
    }
    return () => {};
  }, [data]);

  const sendFunds = () => {
    if (!balance) setAddFunds();
    inputSource(cashAccount);
    changeSection(SECTIONS[2]);
  };

  return (
    currentSection === "Quick Actions" && (
      <Grid
        w={"100%"}
        columnGap={"40px"}
        gridTemplateColumns={"1fr 1fr 1fr 1fr"}
      >
        <GridItem
          h={"200px"}
          borderRadius={"30px"}
          border={`1px solid ${
            currentTheme === "light" ? "#e1e1e1" : "#343C40"
          }`}
          backgroundColor={currentTheme === "light" ? "#f5f5f5" : "#01293E"}
        >
          <VStack
            gap={"6px"}
            transform={"translate(0%, 50%)"}
            alignItems={"center"}
          >
            <Heading
              mb={0}
              color={currentTheme === "light" ? "black" : "white"}
              fontSize={"24px"}
              lineHeight={"normal"}
              fontWeight={"bold"}
            >
              {balance ? `$${addCommas(+balance)}` : "$0"}
            </Heading>
            
            <Text
              display={"flex"}
              alignItems={"center"}
              gap={"4px"}
              color={currentTheme === "light" ? "black" : "white"}
              fontSize={"16px"}
              lineHeight={"normal"}
              fontWeight={"500"}
              mb={"4px"}
            >
              Cash balance
              <Box position={"relative"}>
                <Box
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  id="info"
                  cursor={"pointer"}
                >
                  <Icon display={"inline-block"} as={InfoIcon} />
                </Box>

                <Center
                  _before={{
                    content: '""',
                    borderStyle: "solid",
                    borderWidth: "0 7px 10px 7px",
                    borderColor: `transparent  transparent ${
                      currentTheme === "light" ? "#f9f9f9" : "#343C40"
                    } transparent`,
                    position: "absolute",
                    left: "15px",
                    top: "-9px",
                  }}
                  zIndex={"300"}
                  fontSize={"14px"}
                  lineHeight={"16px"}
                  letterSpacing={"1.3px"}
                  textAlign={"center"}
                  position={"absolute"}
                  py={"10px"}
                  backgroundColor={
                    currentTheme === "light" ? "#f9f9f9" : "#343C40"
                  }
                  px={"14px"}
                  borderRadius={"6px"}
                  top={"calc(100% + 13px)"}
                  left={"-13px"}
                  color={currentTheme === "light" ? "#000" : "#fff"}
                  display={hover ? "block" : "none"}
                  width={"290px"}
                >
                  This is your uninvested cash; transfer it to an external bank,
                  crypto account, or your trading account.
                </Center>
              </Box>
            </Text>
            <Button
              onClick={sendFunds}
              height={"35px"}
              fontSize={"15px"}
              lineHeight={"15px"}
              fontWeight={"500"}
              width={"96px"}
              display={"flex"}
              bg={PRIMARY_COLOR}
              _hover={{
                color: "#0077B5",
                bg: SECONDARY_COLOR,
              }}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"60px"}
              color={"#fff"}
            >
              {!!balance ? "Send" : "Add"}
              {/* Send */}
            </Button> 
          </VStack>
        </GridItem>
        <GridItem
          cursor={"pointer"}
          onClick={() => changeSection(SECTIONS[1])}
          h={"200px"}
          borderRadius={"30px"}
          border={`1px solid ${
            currentTheme === "light" ? "#e1e1e1" : "#343C40"
          } !important`}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"13px"}
          as={VStack}
          bg={"transparent"}
        >
          <Center
            borderRadius={"50%"}
            w={"60px"}
            h={"60px"}
            backgroundColor={
              currentTheme === "light" ? SECONDARY_COLOR : "#01293E"
            }
          >
            <Icon width={"24px"} height={"24px"} as={DownloadIcon} />
          </Center>
          <Text
            color={currentTheme === "light" ? "black" : "white"}
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Add funds
          </Text>
        </GridItem>
        <GridItem
          h={"200px"}
          onClick={() => changeSection(SECTIONS[2])}
          borderRadius={"30px"}
          border={`1px solid ${
            currentTheme === "light" ? "#e1e1e1" : "#343C40"
          } !important`}
          cursor={"pointer"}
          alignItems={"center"}
          gap={"13px"}
          as={VStack}
          justifyContent={"center"}
          bg={"transparent"}
        >
          <Center
            borderRadius={"50%"}
            w={"60px"}
            h={"60px"}
            backgroundColor={
              currentTheme === "light" ? SECONDARY_COLOR : "#01293E"
            }
          >
            <Icon width={"24px"} height={"24px"} as={UploadIcon} />
          </Center>
          <Text
            fontSize={"16px"}
            color={currentTheme === "light" ? "black" : "white"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Send funds
          </Text>
        </GridItem>
        <GridItem
          h={"200px"}
          borderRadius={"30px"}
          border={`1px solid ${
            currentTheme === "light" ? "#e1e1e1" : "#343C40"
          } !important`}
          onClick={() => changeSection(SECTIONS[3])}
          alignItems={"center"}
          gap={"13px"}
          as={VStack}
          justifyContent={"center"}
          bg={"transparent"}
          cursor={"pointer"}
        >
          <Center
            borderRadius={"50%"}
            w={"60px"}
            h={"60px"}
            backgroundColor={
              currentTheme === "light" ? SECONDARY_COLOR : "#01293E"
            }
          >
            <Icon width={"24px"} height={"24px"} as={TransferIcon} />
          </Center>
          <Text
            fontSize={"16px"}
            color={currentTheme === "light" ? "black" : "white"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Transfer funds
          </Text>
        </GridItem>
      </Grid>
    )
  );
};

export default WalletActions;
